import React from "react"
import styles from "./googleReviews.module.css"

export default function GoogleReviews() {
  return (
    <div className={styles.reviewWrapper}>
      <script src="https://reviewsonmywebsite.com/js/v2/embed.js?id=c338ae55b38e487f19ae" type="text/javascript"></script>
      <div data-romw-token="cr0SZvgo0bv0FhfX6J7BVUme4WPtlDEintd8QQBxX4sYm4zCcZ"></div>
    </div>
  )
}