import { useStaticQuery } from "gatsby"
import React from "react"
import config from "../../../data/SiteConfig"
import ResponsiveImage from "../common/responsiveImage"
import ResponsiveImageText from "../common/responsiveImageText"
import HappyFamily from "../images/happyfamily"
import HappyFamilySquare from "../images/happyFamilySquare"
import GoogleReviews from "./googleReviews"
import RatingStars from "./ratingStars"

export default function TestimonialContent() {
  const data = useStaticQuery(graphql`
    query {
      intro: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/(testimonial/1_intro.md)/" }
        }
      ) {
        edges {
          node {
            html
          }
        }
      }
      outro: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/(testimonial/2_outro.md)/" }
        }
      ) {
        edges {
          node {
            html
          }
        }
      }
    }
  `)

  return (
    <>
      <ResponsiveImageText
        image={
          <ResponsiveImage
            portrait={<HappyFamily className={`stretchWide`} />}
            landscape={<HappyFamilySquare />}
            breakpoint={config.layoutBreakpointDesktop}
          />
        }
      >
        <div
          dangerouslySetInnerHTML={{
            __html: data.intro.edges[0].node.html,
          }}
        />
      </ResponsiveImageText>
      <GoogleReviews />
      <div
        dangerouslySetInnerHTML={{
          __html: data.outro.edges[0].node.html,
        }}
      />
      <RatingStars rating={5} />
    </>
  )
}
