import React from "react"
import styles from "./responsiveImageText.module.css"

export default function ResponsiveImageText(props) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.flexContainer}>
        <div className={styles.imageWrapper}>
          <div className={styles.image}>{props.image}</div>
        </div>
        <div className={styles.content}>{props.children}</div>
      </div>
    </div>
  )
}
