import React from "react"
import StarRatings from 'react-star-ratings';

export default function RatingStars(props) {
  return (
    <StarRatings
        rating={props.rating}
        numberOfStars={5}
        starDimension="20px"
        starSpacing="1px"
        starRatedColor="rgb(251,188,4)"
      />
  )
}