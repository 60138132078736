import React from "react"
import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import TestimonialContent from "../components/testimonial/testimonialContent"

const title = `Erfahrungsberichte`

const TestimonialPage = () => (
  <Layout title={title} >
    <SEO title={title} pathname="/erfahrungsberichte" />
    <TestimonialContent />
  </Layout>
)

export default TestimonialPage
